body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.logo {
    display: flex;
    align-items: center;
    margin-left: 2%;
}
.logo img {
    max-height: 50px;
}

.navbar-container{
    justify-content: space-between;
    margin-right: 2%;

    .account-dropdown-container {
        div {
            z-index: 2;
            transform: translateX(-40%);
        }
    }

}

.users-select-container{
    width: 300px;
    margin-left: 20px;
    place-self: center;
    .users-select{
        width: 100%;
        text-align: left;
    }
}