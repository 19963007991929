table{
    margin: 40px 0px;
    border-collapse: collapse;
    user-select: none;
    tbody{
        background-color: blue;
        border: 4px solid #fff;

        tr{
            text-align: center;

            font-size: 1em;
            .puntuacion{
                -webkit-text-stroke: 2px black;
                color: transparent;
                color: #fff;
                text-align: left;
            }


            .saque{
                width: 85px;
                margin-top: 12px;
                border-bottom: 4px solid #fff;
            }

            .parejas{
                margin-right: 0;
                padding: 5px 10px;
                border-bottom: 4px solid #fff;
                width: auto;
                min-height: 86px;
                min-width: 300px;
                justify-content: center;
                align-items: center;
                text-align: left;
                overflow-wrap: anywhere;
            }

            .puntos{
                width: 1.5em;
                padding: 0 10px;
                background: #fff;
                color: blue;
                box-shadow: inset 0 0 0 2px blue;
            }

            .oro{
                width: 1.5em;
                padding: 0 10px;
                background: yellow;
                color: blue;
                box-shadow: inset 0 0 0 2px blue;
            }

            .tbr{
                width: 1.5em;
                padding: 0 10px;
                background: red;
                color: #fff;
                box-shadow: inset 0 0 0 2px #fff;
            }

            .set{
                width: 75px;
                padding: 0 10px;
                border-bottom: 4px solid #fff;
                border-left: 4px solid #fff;
                border-right: 4px solid #fff;
            }

            .nombres{
                display: inline-block;
            }
        }
    }
}

.redo-mobile{
    align-self: center;
    width: 60px;
    height: 40px;
}

.p{
    color: #00ff00;
    font-size: 50px;
}

.buttons-container{
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    place-items: flex-end;
    .name-player{
        height: 38px;
        border-radius: 5px;
    }
    .set-container{

        button{
            margin: 0 20px;
            width: 60px;
        }
    }
}


.reset-button{
    margin-bottom: 20px;
    button{
        padding: 15px 40px;
    }
}
.unredo-button{
    margin-bottom: 20px;
    button{
        padding: 15px 20px;
    }
}

.sport-container{
    display: flex;
    align-items: center;
    h1{
        margin: 10px 40px;
    }
    .create-game-button{
        height: 50px;
    }
}

.grid{
    text-align: left;
}

.hidden-title{
    display: none;
}

.preview{
    margin: 2px;
    tbody {
        background-color: #fff !important;
    }
}

@media screen and (max-width: 770px) {
    .game-container{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .row-name-buttons{
            width: 50px;
        }

        .title-button{
            right: .5em;
            top: .7em;
            position: absolute;
        }

        .preview{
            .preview-row{
                display: flex;
                align-items: center;
                .saque{
                    margin-top: 0;
                    width: 30px !important;
                }
                .preview-field{
                    width: 25px;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .card-body{
            display: flex;
            justify-content: space-around;
            flex-flow: column !important;
            .tabla-puntos-1{
                font-size: 0.6em;
                .set, .puntos{
                    padding: 0 15px;
                    width: 50px;
                }
            }
            .saque{
                width: 30px;
            }


            .buttons-container{
                display: block;
                justify-items: center;
                .set-container{
                    margin-top: 20px;
                    button{
                        width: 80px;
                        margin: 0 10px;
                    }
                }
                .hidden-title{
                    display: block;
                }
            }

            .parejas{
                min-width: auto;
            }
        }
    }

}